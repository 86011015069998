import axios from 'axios'
import { ComponentFactory } from 'components/component-factory'
import Layout from 'components/layout'
import { isArray } from 'lodash'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import React, { ReactElement } from 'react'

import { LandingPageFetcher } from '../ssg-fetcher/landing-page-fetcher'

interface IProps {
  home: any
  seoSlugs: any
}

const IndexPage = ({ home, seoSlugs }: IProps): JSX.Element => {
  return (
    <>
      <Head>
        {home &&
          home.SEO?.length > 0 &&
          isArray(home.SEO) &&
          home.SEO.length > 0 && (
            <>
              <title>{home.SEO[0].Title}</title>
              <meta name="keywords" content={home?.SEO[0].Keywords} />
              <meta
                name="description"
                content={
                  (home?.SEO[0].Description !== ''
                    ? home?.SEO[0].Description
                    : undefined) ?? ''
                }
              />
              <meta name="title" content={home && home?.SEO[0].Title} />
            </>
          )}
      </Head>
      <ComponentFactory data={home.children} />
      {seoSlugs && (
        <NextSeo
          languageAlternates={Object.keys(seoSlugs.slugs).map((k) => {
            return {
              hrefLang: k,
              href: seoSlugs.slugs[k],
            }
          })}
        />
      )}
      <script
        type="text/javascript"
        src="https://t.adcell.com/js/inlineretarget.js?method=track&pid=10882&type=startpage"
        async
      ></script>
    </>
  )
}

IndexPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export async function getStaticProps({ locale }): Promise<any> {
  const rawData = await LandingPageFetcher('/', locale)

  const data =
    rawData.find((page) =>
      page.fullpath.toLowerCase().includes(process.env.NEXT_PUBLIC_FRONTEND_URL)
    ) ?? rawData[0]
  const seoSlugs = await axios.get(
    `https://pim.stayspiced.com/ecom/languagepaths?locale=${locale}&slug=/`
  )

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'footer'])),
      home: data,
      seoSlugs: seoSlugs?.data,
    },
    revalidate: 600,
  }
}

export default IndexPage
